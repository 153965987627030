import React, { Component } from 'react'
import Link from '../utils/link'
//import { Logo } from './icons'
import Logo from '../assets/images/logo.png'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { scrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    requestAnimationFrame(() => {
      this.setState({ scrolled })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--menu-active'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Exa Product Development' className='header__logo' {...props}>
              <img src={Logo} alt='Exa Product Development' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/services/' {...props}>Services</Link></li>
                <li><Link to='/news/' {...props}>News</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger header__hamburger--active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/about/' {...props}>About</Link></li>
                <li><Link to='/services/' {...props}>Services</Link></li>
                <li><Link to='/news/' {...props}>News</Link></li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
