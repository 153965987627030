import React, { Component } from 'react'
import Link from '../utils/link'
import Logo from '../assets/images/logo.png'
import { LinkedIn, Work, Glassdoor, Trace, Twitter } from './icons'
import Bcorp from '../assets/images/bCorp.svg'
import Iso from '../assets/images/ISO9001.svg'
import Flag from '../assets/images/flag.png'
import FlagAlt from '../assets/images/flag-alt.png'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__top'>
          <div className='footer__inner'>
            <div className='footer__left'>
              <Link to='/' title='Exa Product Development' className='footer__logo footer__logo--mobile'>
                <img src={Logo} alt='Exa Product Development' />
              </Link>
              <div>
                <h4>Exa Services Pty. Ltd.</h4>
                <p className='address'><Link to='https://www.google.com/maps/place/Exa+Product+Development/@-37.811478,145.012322,17z/data=!4m5!3m4!1s0x0:0x7d0c48688a16ef45!8m2!3d-37.8112391!4d145.0125835?coh=164777&entry=tt&shorturl=1'>Unit 7 661-663 Victoria Street Abbotsford, VIC, Australia 3067</Link></p>
              </div>
              <div className='copy copy--desktop'>
                <p>© Copyright Exapd {new Date().getFullYear()}</p>
              </div>
            </div>
            <div className='footer__right'>
              <div className='footer__col'>
                <nav className='footer__nav'>
                  <ul>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/about/'>About</Link></li>
                    <li><Link to='/services/'>Services</Link></li>
                    <li><Link to='/news/'>News</Link></li>
                    <li><Link to='/contact/'>Contact</Link></li>
                  </ul>
                </nav>
              </div>
              <div className='footer__col'>
                <nav className='footer__social'>
                  <ul>
                    <li><Link to='https://www.linkedin.com/company/exaproductdevelopment/'><LinkedIn /> LinkedIn</Link></li>
                    <li><Link to='https://work180.com/en-au/for-women/employer/exa-product-development'><Work /> WORK180</Link></li>
                    <li><Link to='https://www.glassdoor.com.au/Overview/Working-at-Exa-Product-Development-EI_IE4144564.11,34.htm'><Glassdoor /> Glassdoor</Link></li>
                    <li><Link to='https://www.our-trace.com/brand/exa-product-development'><Trace /> Trace</Link></li>
                    <li><Link to='https://twitter.com/Exa_PD'><Twitter /> Twitter</Link></li>
                  </ul>
                </nav>
              </div>
              <div className='footer__col'>
              <Link to='https://www.bcorporation.net/en-us/find-a-b-corp/company/exa-product-development'> <img className='bcorp' src={Bcorp} alt='BCorp' /></Link>
              </div>
              <div className='footer__col'>
                <img className='iso' src={Iso} alt='ISO 9001' />
              </div>
            </div>
          </div>
        </div>
        <div className='footer__bottom'>
          <div className='footer__inner'>
            <div className='footer__left'>
              <Link to='/' title='Exa Product Development' className='footer__logo footer__logo--desktop'>
                <img src={Logo} alt='Exa Product Development' />
              </Link>
            </div>
            <div className='footer__right'>
              <div className='footer__col'>
                <img src={Flag} alt='Flag' />
                <img src={FlagAlt} alt='Flag' />
                <p>Exa Product Development acknowledges Aboriginal and Torres Strait Islander people as the Traditional Custodians of the land and acknowledges and pays respect to their Elders, past and present.</p>
              </div>
              <div className='footer__col'>
                <p className='copy copy--mobile'>© Copyright Exapd {new Date().getFullYear()}</p>
                <p>Design by <Link to='https://atollon.com.au'>Atollon</Link></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
